exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dachflachenfenster-js": () => import("./../../../src/pages/Dachflächenfenster.js" /* webpackChunkName: "component---src-pages-dachflachenfenster-js" */),
  "component---src-pages-dammarbeiten-js": () => import("./../../../src/pages/Dämmarbeiten.js" /* webpackChunkName: "component---src-pages-dammarbeiten-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/Datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/Impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/Kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-sanierung-renovierung-js": () => import("./../../../src/pages/Sanierung-Renovierung.js" /* webpackChunkName: "component---src-pages-sanierung-renovierung-js" */),
  "component---src-pages-teppich-laminatboden-js": () => import("./../../../src/pages/Teppich-Laminatböden.js" /* webpackChunkName: "component---src-pages-teppich-laminatboden-js" */),
  "component---src-pages-trockenbau-js": () => import("./../../../src/pages/Trockenbau.js" /* webpackChunkName: "component---src-pages-trockenbau-js" */),
  "component---src-pages-uber-uns-js": () => import("./../../../src/pages/ÜberUns.js" /* webpackChunkName: "component---src-pages-uber-uns-js" */)
}

